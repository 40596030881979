export const appVersion = process.env.REACT_APP_VERSION;

export const defaultDomainStore = "lovleis.com";
export const defaultWarehouseLocationID = "warehouse#bodega.alsacia#depto.303";
export const defaultMarketplaceCurrency = "CLP";
export const defaultLovleisLovmeCategories = "Glam, Chic, Elite";
export const defaultChannels = "pos, store, webstore";

export const UserRole = {
	admin: 0,
	storeManager: 1,
	seller: 2,
	sellerMarketplace: 3,
};

export const RoleName = Object.entries(UserRole).reduce(
	(obj, [key, value]) => ({ ...obj, [value]: key }),
	{}
);

export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "es";

export const localeOptions = [{ id: "es", name: "Español", direction: "ltr" }];

export const urlEcommerceProductDetail = "https://www.lovleis.com/view/product";

export const currencyExchangeRateURL =
	"https://api.dueidee.cl/v1/data/monetray-indexes";
export const labelURL = "https://api.dueidee.cl/v2/orders/[idOrder]/labels/0";

export const backendOauthUrl = process.env.REACT_APP_BACKEND_OAUTH_URL;
export const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
export const clientID = process.env.REACT_APP_CLIENT_ID;

export const thumbsUrl =
	"https://s3.sa-east-1.amazonaws.com/dueidee.cl/stores/lovleis.com/thumbs/";
export const fullImageUrl =
	"https://s3.sa-east-1.amazonaws.com/dueidee.cl/stores/lovleis.com/full/";
export const homeImageUrl =
	"https://s3.sa-east-1.amazonaws.com/dueidee.cl/stores/lovleis.com/home/";
export const noImageFile = "no-image.png";

export const loginRoot = "/user/login";
export const adminRoot = "/app";
export const sellerRoot = "/app/seller";
export const marketplaceRoot = "/app/marketplace";
export const searchPath = `${adminRoot}/#`;

export const shippingCompanyUrl = [
	{
		name: "chilexpress",
		url: "https://centrodeayuda.chilexpress.cl/seguimiento/",
	},
	{ name: "starken", url: "https://www.starken.cl/seguimiento?codigo=" },
	{ name: "paket", url: "https://track.paket.cl/" },
	{
		name: "bluexpress",
		url: "https://tracking-unificado.blue.cl/?n_seguimiento=",
	},
];

export const servicePath = "https://api.coloredstrategies.com";

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = false;
export const defaultColor = "light.purplemonster";
export const isDarkSwitchActive = false;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
	"bluenavy",
	"blueyale",
	"blueolympic",
	"greenmoss",
	"greenlime",
	"purplemonster",
	"orangecarrot",
	"redruby",
	"yellowgranola",
	"greysteel",
];

export const months = [
	{ id: "01", name: "January" },
	{ id: "02", name: "February" },
	{ id: "03", name: "March" },
	{ id: "04", name: "April" },
	{ id: "05", name: "May" },
	{ id: "06", name: "June" },
	{ id: "07", name: "July" },
	{ id: "08", name: "August" },
	{ id: "09", name: "September" },
	{ id: "10", name: "October" },
	{ id: "11", name: "November" },
	{ id: "12", name: "December" },
];

export const comunas = [
	"Aisén",
	"Algarrobo",
	"Alhué",
	"Alto Biobío",
	"Alto del Carmen",
	"Alto Hospicio",
	"Ancud",
	"Andacollo",
	"Angol",
	"Antártica",
	"Antofagasta",
	"Antuco",
	"Arauco",
	"Arica",
	"Buin",
	"Bulnes",
	"Cabildo",
	"Cabo de Hornos",
	"Cabrero",
	"Calama",
	"Calbuco",
	"Caldera",
	"Calera",
	"Calera de Tango",
	"Calle Larga",
	"Camarones",
	"Camiña",
	"Canela",
	"Cañete",
	"Carahue",
	"Cartagena",
	"Casablanca",
	"Castro",
	"Catemu",
	"Cauquenes",
	"Cerrillos",
	"Cerro Navia",
	"Chaitén",
	"Chanco",
	"Chañaral",
	"Chépica",
	"Chiguayante",
	"Chile Chico",
	"Chillán",
	"Chillán Viejo",
	"Chimbarongo",
	"Cholchol",
	"Chonchi",
	"Cisnes",
	"Cobquecura",
	"Cochamó",
	"Cochrane",
	"Codegua",
	"Coelemu",
	"Coihaique",
	"Coihueco",
	"Coinco",
	"Colbún",
	"Colchane",
	"Colina",
	"Collipulli",
	"Coltauco",
	"Combarbalá",
	"Concepción",
	"Conchalí",
	"Concón",
	"Constitución",
	"Contulmo",
	"Copiapó",
	"Coquimbo",
	"Coronel",
	"Corral",
	"Cunco",
	"Curacautín",
	"Curacaví",
	"Curaco de Vélez",
	"Curanilahue",
	"Curarrehue",
	"Curepto",
	"Curicó",
	"Dalcahue",
	"Diego de Almagro",
	"Doñihue",
	"El Bosque",
	"El Carmen",
	"El Monte",
	"El Quisco",
	"El Tabo",
	"Empedrado",
	"Ercilla",
	"Estación Central",
	"Florida",
	"Freire",
	"Freirina",
	"Fresia",
	"Frutillar",
	"Futaleufú",
	"Futrono",
	"Galvarino",
	"General Lagos",
	"Gorbea",
	"Graneros",
	"Guaitecas",
	"Hijuelas",
	"Hualaihué",
	"Hualañé",
	"Hualpén",
	"Hualqui",
	"Huara",
	"Huasco",
	"Huechuraba",
	"Illapel",
	"Independencia",
	"Iquique",
	"Isla de Maipo",
	"Isla de Pascua",
	"Juan Fernández",
	"La Cisterna",
	"La Cruz",
	"La Estrella",
	"La Florida",
	"La Granja",
	"La Higuera",
	"La Ligua",
	"La Pintana",
	"La Reina",
	"La Serena",
	"La Unión",
	"Lago Ranco",
	"Lago Verde",
	"Laguna Blanca",
	"Laja",
	"Lampa",
	"Lanco",
	"Las Cabras",
	"Las Condes",
	"Lautaro",
	"Lebu",
	"Licantén",
	"Limache",
	"Linares",
	"Litueche",
	"Llaillay",
	"Llanquihue",
	"Lo Barnechea",
	"Lo Espejo",
	"Lo Prado",
	"Lolol",
	"Loncoche",
	"Longaví",
	"Lonquimay",
	"Los Álamos",
	"Los Andes",
	"Los Ángeles",
	"Los Lagos",
	"Los Muermos",
	"Los Sauces",
	"Los Vilos",
	"Lota",
	"Lumaco",
	"Machalí",
	"Macul",
	"Máfil",
	"Maipú",
	"Malloa",
	"Marchihue",
	"María Elena",
	"María Pinto",
	"Mariquina",
	"Maule",
	"Maullín",
	"Mejillones",
	"Melipeuco",
	"Melipilla",
	"Molina",
	"Monte Patria",
	"Mostazal",
	"Mulchén",
	"Nacimiento",
	"Nancagua",
	"Natales",
	"Navidad",
	"Negrete",
	"Ninhue",
	"Nogales",
	"Nueva Imperial",
	"Ñiquén",
	"Ñuñoa",
	"O'Higgins",
	"Olivar",
	"Ollagüe",
	"Olmué",
	"Osorno",
	"Ovalle",
	"Padre Hurtado",
	"Padre Las Casas",
	"Paiguano",
	"Paillaco",
	"Paine",
	"Palena",
	"Palmilla",
	"Panguipulli",
	"Panquehue",
	"Papudo",
	"Paredones",
	"Parral",
	"Pedro Aguirre Cerda",
	"Pelarco",
	"Pelluhue",
	"Pemuco",
	"Pencahue",
	"Penco",
	"Peñaflor",
	"Peñalolén",
	"Peralillo",
	"Perquenco",
	"Petorca",
	"Peumo",
	"Pica",
	"Pichidegua",
	"Pichilemu",
	"Pinto",
	"Pirque",
	"Pitrufquén",
	"Placilla",
	"Portezuelo",
	"Porvenir",
	"Pozo Almonte",
	"Primavera",
	"Providencia",
	"Puchuncaví",
	"Pucón",
	"Pudahuel",
	"Puente Alto",
	"Puerto Montt",
	"Puerto Octay",
	"Puerto Varas",
	"Pumanque",
	"Punitaqui",
	"Punta Arenas",
	"Puqueldón",
	"Purén",
	"Purranque",
	"Putaendo",
	"Putre",
	"Puyehue",
	"Queilén",
	"Quellón",
	"Quemchi",
	"Quilaco",
	"Quilicura",
	"Quilleco",
	"Quillón",
	"Quillota",
	"Quilpué",
	"Quinchao",
	"Quinta de Tilcoco",
	"Quinta Normal",
	"Quintero",
	"Quirihue",
	"Rancagua",
	"Ránquil",
	"Rauco",
	"Recoleta",
	"Renaico",
	"Renca",
	"Rengo",
	"Requínoa",
	"Retiro",
	"Rinconada",
	"Río Bueno",
	"Río Claro",
	"Río Hurtado",
	"Río Ibáñez",
	"Río Negro",
	"Río Verde",
	"Romeral",
	"Saavedra",
	"Sagrada Familia",
	"Salamanca",
	"San Antonio",
	"San Bernardo",
	"San Carlos",
	"San Clemente",
	"San Esteban",
	"San Fabián",
	"San Felipe",
	"San Fernando",
	"San Gregorio",
	"San Ignacio",
	"San Javier",
	"San Joaquín",
	"San José de Maipo",
	"San Juan de la Costa",
	"San Miguel",
	"San Nicolás",
	"San Pablo",
	"San Pedro",
	"San Pedro de Atacama",
	"San Pedro de la Paz",
	"San Rafael",
	"San Ramón",
	"San Rosendo",
	"San Vicente",
	"Santa Bárbara",
	"Santa Cruz",
	"Santa Juana",
	"Santa María",
	"Santiago",
	"Santo Domingo",
	"Sierra Gorda",
	"Talagante",
	"Talca",
	"Talcahuano",
	"Taltal",
	"Temuco",
	"Teno",
	"Teodoro Schmidt",
	"Tierra Amarilla",
	"Tiltil",
	"Timaukel",
	"Tirúa",
	"Tocopilla",
	"Toltén",
	"Tomé",
	"Torres del Paine",
	"Tortel",
	"Traiguén",
	"Treguaco",
	"Tucapel",
	"Valdivia",
	"Vallenar",
	"Valparaíso",
	"Vichuquén",
	"Victoria",
	"Vicuña",
	"Vilcún",
	"Villa Alegre",
	"Villa Alemana",
	"Villarrica",
	"Viña del Mar",
	"Vitacura",
	"Yerbas Buenas",
	"Yumbel",
	"Yungay",
	"Zapallar",
];

export const regiones = [
	"Región Aisén del Gral.Carlos Ibáñez del Campo",
	"Región de Antofagasta",
	"Región de Arica y Parinacota",
	"Región de Atacama",
	"Región de Coquimbo",
	"Región de La Araucanía",
	"Región de Los Lagos",
	"Región de Los Ríos",
	"Región de Magallanes y de la Antártica Chilena",
	"Región de Ñuble",
	"Región de Tarapacá",
	"Región de Valparaíso",
	"Región del Biobío",
	"Región del Libertador Gral. Bernardo O’Higgins",
	"Región del Maule",
	"Región Metropolitana de Santiago",
];
